import React, { useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import css from './SubscriptionCard.module.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { getSubscriptionDetails } from '../../util/subscription';
import { stripeOperations } from '../../util/api';
import { useSubscription } from '../../context/SubscriptionContext';
import LoadingSpinnerStripe from '../LoadingSpinnerStripe/LoadingSpinnerStripe';

const SubscriptionCard = React.memo(({ currentUser }) => {
  const [transform, setTransform] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const subscription = useSubscription();
  const setPendingSubscriptionRedirect = subscription?.setPendingSubscriptionRedirect;
  
  const subscriptionDetails = useMemo(() => getSubscriptionDetails(currentUser), [currentUser]);
  const hasActiveSubscription = useMemo(() => 
    subscriptionDetails?.status === 'active' || subscriptionDetails?.status === 'canceling',
    [subscriptionDetails]
  );

  const handleClick = useCallback(async () => {
    setIsLoading(true);
    
    // Verificar si el usuario está autenticado
    if (!currentUser || !currentUser.id || !currentUser.attributes?.email) {
      // Verificar si estamos en el cliente
      const isClient = typeof window !== 'undefined';
      if (isClient) {
        sessionStorage.setItem('fromSubscription', 'true');
      }
      // Solo llamar setPendingSubscriptionRedirect si existe
      if (setPendingSubscriptionRedirect) {
        setPendingSubscriptionRedirect(true);
      }
      setIsLoading(false);
      history.push('/signup');
      return;
    }

    try {
      if (hasActiveSubscription) {
        const { url } = await stripeOperations.createPortalSession({
          customerId: currentUser.attributes.profile.metadata.stripeCustomerId,
        });
        if (url) window.location.href = url;
      } else {
        const { url } = await stripeOperations.createCheckoutSession({
          userId: currentUser.id.uuid,
          userEmail: currentUser.attributes.email,
        });
        if (url) window.location.href = url;
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  }, [currentUser, hasActiveSubscription, history, setPendingSubscriptionRedirect]);

  const handleMouseMove = useCallback((e) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    const rotateX = (y - centerY) / 25;
    const rotateY = -(x - centerX) / 25;

    setTransform(`perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setTransform('perspective(1000px) rotateX(0deg) rotateY(0deg)');
  }, []);

  // Memoize the card content
  const cardContent = useMemo(() => (
    <>
      <div className={css.cardHeader}>
        <div className={css.pricing}>
          <span className={css.price}>$35</span>
          <span className={css.period}>/month</span>
        </div>
      </div>
      
      <div className={css.cardContent}>
        <div className={css.benefitsSection}>
          {/* <h3 className={css.sectionTitle}>Subscription Benefits:</h3> */}
          <ul className={css.benefitsList}>
            <li className={css.benefitItem}>
              <span className={css.icon}>🕐</span>
              <span>
                <span className={css.underlinedText}>
                  1 free hour per booking
                  <span className={css.tooltip2}>
                    Valid for the first 3 bookings of each month, when booking 2 or more hours
                  </span>
                </span>
              </span>
            </li>
            <li className={css.benefitItem}>
              <span className={css.icon}>👥</span>
              <span className={css.underlinedText}>
                Hyper-access to Stuvi's Community
                <span className={css.tooltip}>
                  • Discord Access
                  <br />
                  • Exclusive live events
                  <br />
                  • Stuvi's Playlists
                </span>
              </span>
            </li>
            <li className={css.benefitItem}>
              <span className={css.icon}>📍</span>
              <span className={css.underlinedText}>
                Support local studios
                <span className={css.tooltip2}>
                  By booking through Stuvi with Creator+, we can lower our commission to basically only cover the cost of the transaction, making local studios a lot more money. 
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>
      
      <div className={css.cardFooter}>
        <button 
          className={css.subscribeButton}
          onClick={handleClick}
        >
          <FormattedMessage 
            id={hasActiveSubscription 
              ? "SubscriptionCard.manageSubscription" 
              : "SubscriptionCard.subscribe"
            } 
          />
        </button>
      </div>
    </>
  ), [hasActiveSubscription, handleClick]);

  return (
    <>
      {isLoading && <LoadingSpinnerStripe />}
      <div 
        className={css.card}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{ transform }}
      >
        {cardContent}
      </div>
    </>
  );
});

SubscriptionCard.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.shape({
      uuid: PropTypes.string
    }),
    attributes: PropTypes.shape({
      email: PropTypes.string,
      profile: PropTypes.shape({
        metadata: PropTypes.shape({
          stripeCustomerId: PropTypes.string
        })
      })
    })
  })
};

export default SubscriptionCard; 