import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSubscription } from '../../context/SubscriptionContext';
import css from './SubscriptionCancelPage.module.css';

const SubscriptionCancelPage = () => {
    const history = useHistory();
    const { setPendingSubscriptionRedirect } = useSubscription();

    useEffect(() => {
        // Clean the state of redirect
        setPendingSubscriptionRedirect(false);
        sessionStorage.removeItem('fromSubscription');
    }, [setPendingSubscriptionRedirect]);

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '80vh',
        padding: '2rem',
        background: 'linear-gradient(135deg, #1345CA 0%, #0B0D17 100%)',
        color: '#FFFFFF',
        textAlign: 'center'
    };

    return (
        <div style={containerStyle}>
            <img
                src={require('../../assets/michael-sad.png')}
                alt="Michael being sad" 
                className={css.logo}
                style={{ 
                    maxWidth: '200px', 
                    maxHeight: '200px', 
                    width: 'auto', 
                    height: 'auto', 
                    borderRadius: '15px',
                }}
            />
            <h1 style={{ marginBottom: '0px' }}>Subscription not completed</h1>
            <p>Your subscription was not completed. Feel free to try again. If you have any questions or the problem persists, please contact us.</p>
            <button
                onClick={() => history.push('/p/stuvi-creator')}
                className={css.returnButton}
            >
                Back to Stuvi Creator Page
            </button>
        </div>
    );
};

export default SubscriptionCancelPage; 