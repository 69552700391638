import React, { useState, useEffect } from 'react';
import css from './LoadingSpinnerStripe.module.css';
import michaelCool from '../../assets/michael-cool.png';

const LoadingSpinner = () => {
  const [dots, setDots] = useState('.');
  const [counter, setCounter] = useState(3);

  useEffect(() => {
    // Dots animation
    const dotsInterval = setInterval(() => {
      setDots(prev => prev.length >= 3 ? '.' : prev + '.');
    }, 500);

    return () => {
      clearInterval(dotsInterval);
    };
  }, []);

  return (
    <div className={css.loadingContainer}>
      <img 
        src={michaelCool} 
        alt="Loading..." 
        className={css.loadingSpinner}
      />
      <p className={css.loadingText}>
        Going to Stripe{dots}
      </p>
    </div>
  );
};

export default LoadingSpinner; 